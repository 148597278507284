<template>
  <b-card class="panel" data-report="intervals" :class="{ 'is-loading': loading }" no-body>
    <h3 class="panel-title">Интервалы</h3>
    <intervals
      entityName="employees"
      :id="$store.state.auth.user.employee.id"
      @loaded="loading = false"
      :extraQuery="extraQuery"
    ></intervals>

    <div class="panel-loading">
      <div class="loader loader-default"></div>
    </div>
  </b-card>
</template>

<script>
  import Intervals from '../Reports/Intervals/Extension'

  export default {
    name: 'DashboardIntervals',
    components: {
      Intervals,
    },
    data() {
      return {
        loading: true,
        extraQuery: {
          filters: {
            date: {
              from: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            },
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  [data-report='intervals'] {
    min-height: 200px;
    /deep/ {
      .filters,
      .pagination {
        display: none;
      }
      span.loading {
        padding: 20px 30px;
      }
      .card {
        margin-bottom: 0;
      }
    }
  }
</style>
