<template>
  <b-card class="panel" :class="{ 'is-loading': loading }" no-body title="Сводка">
    <h3 v-if="!user" class="panel-title">Сводка по ЗП</h3>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th v-for="period in periods" class="text-center">{{ period.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Часы</td>
          <td v-for="period in periods" class="text-center">
            {{ data | getVar('0.' + period.alias, 0) | formatDuration('h:mm') }}&nbsp;ч.
          </td>
        </tr>
        <tr>
          <td>Оклад</td>
          <td v-for="period in periods" class="text-center" style="font-weight: bold">
            {{ data | getVar('1.' + period.alias, 0) | formatNumber('0,0.00') }}&nbsp;&#8381;
          </td>
        </tr>
        <tr>
          <td>Бонусы</td>
          <td v-for="period in periods" class="text-center">-</td>
        </tr>
        <tr>
          <td>Штрафы</td>
          <td v-for="period in periods" class="text-center">-</td>
        </tr>
        <tr>
          <td>Итого ЗП</td>
          <td v-for="period in periods" class="text-center" style="font-weight: bold">
            {{ data | getVar('1.' + period.alias, 0) | formatNumber('0,0.00') }}&nbsp;&#8381;
          </td>
        </tr>
      </tbody>
    </table>

    <div class="panel-loading">
      <div class="loader loader-default"></div>
    </div>
  </b-card>
</template>

<script>
  import store from '../../store'
  import api from '@/assets/js/api'
  import get from 'lodash/get'

  export default {
    name: 'salary',
    props: ['user'],
    filters: {
      getVar(obj, param, def) {
        return get(obj, param, def)
      },
    },
    data() {
      return {
        loading: true,
        periods: [
          {
            name: 'Сегодня',
            alias: 'today',
          },
          {
            name: 'На этой неделе',
            alias: 'this_week',
          },
          {
            name: 'В этом месяце',
            alias: 'this_month',
          },
          {
            name: 'В прошлом месяце',
            alias: 'last_month',
          },
        ],
        data: {},
      }
    },
    created() {
      api.base
        .get({
          path: 'dashboard/boxes/salary/summary',
          params: {
            filters: {
              salary: {
                summary: {
                  user_id: this.user || store.state.auth.user.id,
                },
              },
            },
          },
        })
        .then(result => {
          this.data = result
          this.loading = false
        })
    },
  }
</script>

<style lang="scss" scoped>
  .table {
    /deep/ {
      th {
        &:not(:first-child) {
          width: 200px;
        }
      }
    }
  }
</style>
