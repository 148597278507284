<template>
  <div class="view">
    <salary></salary>
    <intervals></intervals>
  </div>
</template>

<script>
  import Salary from './Salary'
  import Intervals from './Intervals'

  export default {
    name: 'Dashboard',
    components: {
      Intervals,
      Salary,
    },
  }
</script>
